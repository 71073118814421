<template>
  <div class="_bg-default _80w">
    <div class="_100vh __profile">
      <div class="_100top _60-w pa-3" v-if="person">
        <!-- INTRO -->
        <v-card class="radius-card">
          <v-img src="../../assets/img/meptest.jpeg" height="120px"></v-img>
          <v-overlay :value="loadCard" :absolute="true" color="white">
            <v-progress-circular
              color="grey"
              indeterminate
              size="35"
            ></v-progress-circular>
          </v-overlay>
          <div class="pa-3 d-flex justify-space-between">
            <v-avatar color="white" size="150" class="min-img">
              <img
                :src="
                  person.data.photo_profile
                    ? `${env}/upload/photo_profile/${person.data.id}/${person.data.photo_profile}`
                    : dummy
                "
                class="img-fit"
                alt="asd"
              />
            </v-avatar>
            <!-- button untuk connect ketika status not_friend -->
            <div class="d-flex" v-if="role == 3">
              <v-btn
                v-if="person.status == 'not_friend'"
                color="green"
                class="mr-2"
                dark
                small
                fab
                depressed
                content="Let's Connect!"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light'
                }"
                @click="connect(person.data.id)"
              >
                <v-icon small>mdi-account-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="person.status == 'confirmed'"
                color="blue"
                class="mr-2"
                dark
                depressed
                small
                fab
                content="Connected!"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light'
                }"
              >
                <v-icon small>mdi-account-check</v-icon>
              </v-btn>
              <!-- button untuk pending ketika status pending -->
              <v-btn
                v-if="person.status == 'pending'"
                color="orange"
                class="mr-2"
                dark
                small
                depressed
                fab
                content="Pending"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light'
                }"
              >
                <v-icon small>mdi-account-arrow-right</v-icon>
              </v-btn>
              <!-- button untuk unfriend ketika status confirmed -->
              <v-btn
                v-if="person.status == 'confirmed'"
                color="red"
                class="mr-2"
                dark
                depressed
                small
                fab
                content="Disconnect"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light'
                }"
                @click="unfriend(person.data.id)"
              >
                <v-icon small>mdi-account-remove</v-icon>
              </v-btn>
              <v-btn
                color="grey"
                class="mr-2"
                dark
                small
                fab
                depressed
                content="Block User"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light'
                }"
                @click="block(person.data.id)"
              >
                <v-icon small>mdi-account-cancel</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="px-3">
            <h3>{{ person.data.nama_lengkap }}</h3>
            <section v-if="person.data.data_bidang">
              <p>
                {{ person.data.data_bidang.nama }} di
                {{
                  person.data.data_kota1
                    ? person.data.data_kota1.nama_kota
                    : "-"
                }}
              </p>
            </section>
            <section v-else>
              <p>-</p>
            </section>
          </div>
          <div class="pa-2">
            <v-row>
              <v-col cols="12" md="5">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-cellphone</v-icon> Phone</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  person.data.no_hp
                }}</v-col>
              </v-col>
              <v-col cols="12" md="7">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-map-marker</v-icon> Location</v-col
                >
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  v-if="person.data.data_kota1"
                  >{{ person.data.data_kota1.nama_kota }},
                  {{ person.data.data_kota1.data_provinsi.nama_provinsi }}
                </v-col>
                <v-col cols="12" md="12" class="py-0" v-else>- </v-col>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-email</v-icon> E-mail</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  person.data.email
                }}</v-col>
              </v-col>
              <v-col cols="12" md="7">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-map-marker-plus</v-icon> Additional
                  Location</v-col
                >
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  v-if="person.data.data_kota2"
                  >{{ person.data.data_kota2.nama_kota }},
                  {{
                    person.data.data_kota2.data_provinsi.nama_provinsi
                  }}</v-col
                >
                <v-col cols="12" md="12" class="py-0" v-else>-</v-col>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <!-- ABOUT -->
        <v-card class="radius-card mt-3 pa-3">
          <div class="mb-2 d-flex justify-space-between">
            <h3>About</h3>
          </div>
          <div v-if="!person.data.profile_singkat">
            <p class="grey--text">-</p>
          </div>
          <article>
            <p>
              {{ person.data.profile_singkat }}
            </p>
          </article>
        </v-card>

        <!-- SKILL AND COMPETENCIES -->
        <v-card class="radius-card pa-3 mt-3">
          <div class="pa-3 d-flex justify-space-between">
            <h3>Skills and Competencies</h3>
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="item in person.data.skill_kompetensi" :key="item">
              <v-chip class="ma-2" color="orange" label outlined>
                {{ item }}
              </v-chip>
            </div>
          </div>
          <div v-if="!person.data.skill_kompetensi">-</div>
        </v-card>
        <!-- RECOMENDATION -->
        <v-card class="radius-card mt-3 pa-3" v-if="recomendation">
          <div class="mb-2">
            <h3>Recomendation</h3>
          </div>
          <v-btn
            v-if="haveMyRecomendation"
            block
            color="blue"
            rounded
            outlined
            @click="dialogRecomend = true"
            >Tambahkan rekomendasi</v-btn
          >
          <section class="_full-w mt-2">
            <v-carousel
              v-model="slide"
              hide-delimiters
              height="auto"
              show-arrows-on-hover
            >
              <v-carousel-item v-for="item in recomendation" :key="item.id">
                <v-card class="pa-2 white" height="100%">
                  <v-card
                    class="
                      radius-card
                      py-2
                      px-5
                      white
                      d-flex
                      flex-column
                      justify-space-between
                    "
                    height="100%"
                  >
                    <section class="black--text mb-3">
                      <v-icon color="grey">mdi-format-quote-open</v-icon>
                      <p class="ma-0">
                        {{ item.catatan }}
                      </p>
                      <div class="d-flex justify-end">
                        <v-icon color="grey">mdi-format-quote-close</v-icon>
                      </div>
                    </section>
                    <footer class="d-flex align-center justify-end">
                      <v-avatar class="mr-2">
                        <img
                          :src="
                            item.data_pemberi.photo_profile
                              ? `${env}/upload/photo_profile/${item.pemberi_rekomendasi}/${item.data_pemberi.photo_profile}`
                              : dummy
                          "
                          alt=""
                          class="img-fit"
                        />
                      </v-avatar>
                      <div class="black--text">
                        <h4 class="ma-0 pa-0 line__height">
                          {{ item.data_pemberi.nama_lengkap }}
                        </h4>
                      </div>
                    </footer>
                  </v-card>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </section>
        </v-card>
        <v-card class="radius-card pa-3 mt-3">
          <div class="d-flex flex-wrap">
            <v-btn block color="primary" @click="downloadCv(person.data)">
              Download CV
            </v-btn>
          </div>
        </v-card>
        <div class="padd__to_bottom__"></div>
      </div>
      <div class="left__top _10w _100vh" v-if="person">
        <!-- HISTORY EDUCATION -->
        <v-col>
          <v-card
            class="radius-card pa-3"
            v-if="person.data.data_histori_pendidikan"
          >
            <div class="pa-3 d-flex justify-space-between">
              <h3>Education</h3>
            </div>

            <div v-if="person.data.data_histori_pendidikan.length">
              <section
                v-for="val in person.data.data_histori_pendidikan"
                :key="val.id"
                class="mb-2"
              >
                <div>
                  <v-icon>mdi-school</v-icon> {{ val.tingkat }} di
                  {{ val.nama_lembaga_pendidikan }}
                </div>
                <div class="d-flex flex-column">
                  <!-- <small class="ml-7 grey--text"
                  >Master of psycholog (Clinical psycholog)</small
                > -->
                  <small class="ml-7 grey--text"
                    >Tahun Lulus : {{ val.tahun_lulus }}</small
                  >
                </div>
              </section>
            </div>
            <div v-else>
              <p>-</p>
            </div>
          </v-card>

          <!-- EXPERIENCES -->
          <v-card
            class="radius-card pa-3 mt-3"
            v-if="person.data.data_pengalaman_kerja"
          >
            <div class="pa-3 d-flex justify-space-between">
              <h3>Experiences</h3>
            </div>
            <div v-if="person.data.data_pengalaman_kerja.length">
              <section
                v-for="val in person.data.data_pengalaman_kerja"
                :key="val.id"
                class="mb-2"
              >
                <div>
                  <v-icon>mdi-office-building-marker-outline</v-icon>
                  {{ val.posisi_jabatan }} di
                  {{ val.nama_perusahaan }}
                </div>
                <div class="d-flex flex-column">
                  <!-- <small class="ml-7 grey--text"
                  >Master of psycholog (Clinical psycholog)</small
                > -->
                  <small class="ml-7 grey--text"
                    >Periode : {{ val.periode }}</small
                  >
                </div>
              </section>
            </div>
            <div v-else>
              <p>-</p>
            </div>
          </v-card>

          <!-- FEEDSSSSSSSSSSSSSSSS -->
          <!-- <v-card class="radius-card mt-3 pa-3">
          <v-btn depressed dark block color="grey" @click="dialogFeed = true"
            ><h3>My Feeds</h3></v-btn
          >
        </v-card>
        <div v-if="myFeed">
          <v-card
            class="radius-card my-4"
            v-for="(feed, idx) in myFeed.data"
            :key="`${idx}key`"
          >
            <section class="pa-3">
              <div class="d-flex">
                <v-avatar class="mr-3">
                  <img
                    src="https://i.pinimg.com/564x/0d/ea/1c/0dea1c6558eb716b36991ddb32424575.jpg"
                    class="img-fit"
                    alt=""
                  />
                </v-avatar>
                <div>
                  <h4>Elon Musk</h4>
                  <small class="grey--text">Kemarin</small>
                </div>
              </div>
            </section>
            <article class="px-3">{{ feed.content }}</article>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue">
                8
                <v-icon>mdi-comment</v-icon>
              </v-btn>

              <v-btn text color="red">
                19
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div> -->
        </v-col>
        <div class="pa-3" v-if="person">
          <GiveRecomendation
            v-bind:person="person.data"
            v-bind:dialogRecomend="dialogRecomend"
            @close="closeDialog"
            @refetch="getRecomendation"
          />
        </div>
      </div>
      <div v-else-if="loading">
        <Waiting />
      </div>
      <div
        v-if="!person"
        class="_100top pa-3 d-flex flex-column align-center _full-w"
      >
        <img src="../../assets/img/404.svg" alt="404" height="200px" />
        <h3>{{ err }}</h3>
      </div>
      <PreviewCv
        v-bind:dialogCV="dialogCV"
        v-bind:profile="profileCV"
        @close="closeDialog(1)"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import GiveRecomendation from "../../components/Psycholog/Modal/giveRecomendation.vue";
import Waiting from "../../components/_base/Modal/waiting.vue";
import PreviewCv from "../../components/Psycholog/Modal/Personal/previewCv.vue";
// import { Carousel } from "vue-carousel";

export default {
  name: "isAuthpsycholog",
  computed: {
    ...mapState({
      env: state => state.API_URL,
      myFeed: state => state.feeds.feeds,
      user_id: state => state.id,
      person: state => state.person,
      role: state => state.role,
      recomendation: state => state.psycholog.listRecomendation,
      errMsg: state => state.errMsg,
      profile: state => state.psycholog.myProfile,
      dummy: state => state.dummy
    })
  },
  components: {
    Waiting,
    GiveRecomendation,
    PreviewCv
    // Carousel,
  },
  data() {
    return {
      loading: false,
      loadCard: false,
      dialogIntro: false,
      dialogAbout: false,
      dialogEducate: false,
      dialogExperiences: false,
      dialogRecomend: false,
      slide: 0,
      haveMyRecomendation: true,
      err: "",
      dialogCV: false,
      profileCV: null
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.fetch();
    }
  },
  mounted() {
    this.$store.dispatch("feeds/myFeeds");
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      let username = this.$route.params.username;
      this.$store
        .dispatch("viewPsycholog", username)
        .then(data => {
          this.loading = false;
          this.getRecomendation(data.data.id);
        })
        .catch(err => {
          console.log(err);
          this.err = err;
          this.loading = false;
        });
    },

    connect(id) {
      this.loadCard = true;
      let data = new FormData();
      data.append("target_id", id);
      this.$store.dispatch("friendship/sendRequest", data).then(data => {
        this.loadCard = false;
        console.log(data);
        if (data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
        this.fetch();
      });
    },

    cancelReq(id) {
      this.loadCard = true;
      let data = new FormData();
      data.append("request_id", id);
      this.$store.dispatch("friendship/rejectRequest", data).then(data => {
        this.loadCard = false;
        console.log(data);
        if (data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
        this.fetch();
      });
    },

    unfriend(id) {
      this.loadCard = true;
      let data = new FormData();
      data.append("target_id", id);
      this.$store.dispatch("friendship/unfriend", data).then(data => {
        this.loadCard = false;
        console.log(data);
        if (data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
        this.fetch();
      });
    },

    block(id) {
      this.loadCard = true;
      let data = new FormData();
      data.append("target_id", id);
      this.$store.dispatch("friendship/blockFriends", data).then(data => {
        console.log(data);
        this.loadCard = false;
        if (data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
        this.fetch();
      });
    },

    getRecomendation(id) {
      this.$store.dispatch("psycholog/getRecomendation", id).then(data => {
        let list = data;
        if (list.length) {
          console.log("masuk sini");
          let isGiveRecomend = list.find(
            el => el.pemberi_rekomendasi == this.user_id
          );
          if (isGiveRecomend) {
            this.haveMyRecomendation = false;
          }
        }
      });
    },

    downloadCv() {
      this.profileCV = this.person;
      this.dialogCV = true;
    },

    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogCV = false;
          break;
      }
    }
  }
};
</script>

<style scoped>
.__profile {
  display: flex;
}
._60-w {
  width: 60%;
}
._10w {
  width: 40%;
  /* padding-right: 20px; */
}
.min-img {
  margin-top: -120px;
  border: 2px white solid;
}
.line__height {
  line-height: 0.1;
}
.padd__to_bottom__ {
  height: 100px;
}
.left__top {
  margin-top: 90px;
}
@media (max-width: 576px) {
  .padd__to_bottom__ {
    display: flex;
    justify-content: center;
    height: 0;
  }
  .left__top {
    margin-top: 0;
  }
  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
@media (max-width: 768px) {
  .left__top {
    margin-top: 0px;
  }
  .padd__to_bottom__ {
    height: 0;
  }

  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
</style>
